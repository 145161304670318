<template>
    <section class="wrap-filter">
        <div class="wrap-filter-content">
            <div class="filter__date range">
                <div class="form-group">
                    <label for="">Deposit Date</label>
                    <input type="month" v-model="selectDate" :max="today" />
                </div>
            </div>
            <div class="filter__owner">
                <div class="form-group">
                    <label for="">Owner</label>
                    <select name="" id="" v-model="form.owner" v-if="typeTable == 'offexchange'">
                        <option value="" selected disabled>Choose Owner</option>
                        <option v-for="client in dataClient" :key="client" :value="client">{{ client }}</option>
                    </select>
                    <select class="form-input" v-model="form.owner" v-else>
                        <option value="" selected disabled>Choose Owner</option>
                        <option value="">ALL</option>
                        <option value="LP000009">LP000009</option>
                        <option value="CP000002">CP000002</option>
                    </select>
                </div>
            </div>
            <div class="filter__serial">
                <div class="form-group">
                    <label for="">Serial Number</label>
                    <input type="text" placeholder="MTLG12xxxx" v-model="form.serial_number" />
                </div>
            </div>
            <div class="filter__status">
                <div class="form-group">
                    <label for="">Status</label>
                    <select name="" id="" v-model="form.status">
                        <option value="" selected disabled>Choose Status</option>
                        <option value="001" class="vendor">Request Deposit</option>
                        <option value="002" class="abx">Operator Approve</option>
                        <option value="003" class="clearing">Clearing Confirmation</option>
                        <option value="004" class="stored">KMI Confirmation (Stored)</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="wrap-button">
            <button type="button" class="btn" @click="resetFilter">Reset</button>
            <button type="button" class="btn btn-success" @click="selectFilter">Apply</button>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        typeTable: {
            type: String,
        },
        dataClient: Array,
    },
    data() {
        return {
            form: {
                dateType: "",
                owner: "",
                serial_number: "",
                status: "",
                hallmark: "",
                startDate: "",
                endDate: "",
            },
            selectDate: "",
            today: "",
            month: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            currentMonth: "",
        };
    },
    mounted() {
        this.today = new Date().toISOString().split("T")[0];
        this.today = this.today.slice(0, 7);
        this.currentMonth = this.month[new Date().getMonth()];
    },
    methods: {
        selectFilter() {
            const date = new Date(this.selectDate);
            const month = date.getMonth();
            const year = date.getFullYear();
            const maxDate = new Date(year, month + 1, 0).getDate();
            if (this.selectDate != "") {
                this.form.startDate = this.selectDate + "-01";
                this.form.endDate = this.selectDate + "-" + JSON.stringify(maxDate);
            } else {
                this.form.startDate = "";
                this.form.endDate = "";
            }
            this.$emit("setDataFilter", this.form);
        },
        resetFilter() {
            this.form = {
                dateType: "",
                owner: "",
                serial_number: "",
                status: "",
                hallmark: "",
                startDate: "",
                endDate: "",
            };

            this.selectDate = "";
            this.$emit("setDataFilter", this.form);
        },
    },
};
</script>

<style scoped>
.wrap-filter {
    padding: 0.75rem;
    border: solid 1px #ddd;
    border-radius: 5px;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    font-size: 13.5px;
}

.wrap-filter-content {
    display: flex;
    gap: 2rem;
}
.wrap-filter__date {
    display: flex;
    gap: 2rem;
}

.filter__date {
    flex: 1;
}
.form-group label {
    display: block;
}

.form-group input,
select {
    width: 100%;
    margin-bottom: 0.75rem;
    border: 1px solid #ddd;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
}
.form-group input {
    font-size: 12px;
}

.form-group button {
    width: 100%;
    font-size: 13px !important;
}

.wrap-button {
    display: flex;
    justify-content: end;
    gap: 1rem;
}

.btn {
    font-size: 12px;
    padding: 0.5rem;
    line-height: 0.7 !important;
}
</style>
