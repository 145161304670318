<template>
    <div class="list-deposit">
        <div class="d-flex justify-content-end mb-3">
            <div class="wrap-filter">
                <b-button class="btn btn-info btn-sm mr-1" @click="isShowFilter = !isShowFilter">Filter</b-button>
            </div>
        </div>
        <filter-list-deposit v-if="isShowFilter" :typeTable="typeDeposit" :dataClient="dataClient" @setDataFilter="setFIlterTable"></filter-list-deposit>
        <div class="view-perpages">
            <select v-model="pageNumber" @change="showDataRow">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
            <span>&nbsp;entries per page</span>
        </div>
        <table>
            <thead>
                <tr id="top">
                    <th scope="col" style="width: 70px">No</th>
                    <th scope="col">Owner</th>
                    <th scope="col">Deposit Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Total Item</th>
                    <th scope="col">Total Weight</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody v-if="isLoadingData">
                <tr>
                    <td colspan="10">
                        <b-spinner></b-spinner>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="(data, i) in depositData" :key="i" v-show="depositData != 0">
                    <td data-label="No">{{ (currentPage - 1) * perPage + (i + 1) }}</td>
                    <td data-label="Transaction">{{ data.owner }}</td>
                    <td data-label="Deposite Date">{{ data.deposit_date | formatDate }}</td>
                    <td data-label="Status">
                        <div v-if="typeDeposit == 'onexchange'">
                            <span v-show="data.status == '001'" class="abx">Request Deposit</span>
                            <span v-show="data.status == '002'" class="operator">Operator Approve</span>
                            <span v-show="data.status == '003'" class="clearing">Clearing Confirmation</span>
                            <span v-show="data.status == '004'" class="stored">KMI Confirmation (Stored)</span>
                        </div>
                        <div v-else>
                            <span v-show="data.status == '001'" class="vendor">Vendor Emas Add Detail </span>
                            <span v-show="data.status == '002'" class="abx">KMI Approve, Vendor store the gold</span>
                            <span v-show="data.status == '003'" class="operator">Operator Approve</span>
                            <span v-show="data.status == '004'" class="clearing">Clearing Approve</span>
                            <span v-show="data.status == '005'" class="stored">Stored</span>
                            <span v-show="data.status == '006'" class="reject">Reject</span>
                            <span v-show="data.status == '007'" class="stored">Has Been Withdrawn</span>
                        </div>
                    </td>
                    <td data-label="Total Item">{{ data.deposit_detail ? data.deposit_detail.total_item : "" }}</td>
                    <td data-label="Total Weight">{{ data.deposit_detail ? data.deposit_detail.total_weight : "" }}</td>

                    <td data-label="Action">
                        <router-link :to="'/on-exchange/deposit/' + data._id">
                            <b-button variant="outline-info" size="sm" class="m-1">Detail</b-button>
                        </router-link>
                    </td>
                </tr>
                <tr v-show="depositData.length == 0">
                    <td colspan="10">No Data Available in Table</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import FilterListDeposit from "../FilterListDeposit.vue";
export default {
    components: { FilterListDeposit },
    props: {
        depositData: Array,
        currentPage: Number,
        perPage: Number,
        typeDeposit: String,
        isLoadingData: Boolean,
        dataClient: Array,
    },

    data() {
        return {
            isShowFilter: false,
            pageNumber: this.perPage,
        };
    },
    methods: {
        setFIlterTable(event) {
            this.$emit("filterTable", event);
        },

        showDataRow() {
            this.$emit("changeRow", this.pageNumber);
        },
    },
};
</script>

<style></style>
