<template>
    <div class="wrap-dashboard">
        <div class="nav-tab">
            <span class="tab" :class="{ activeTab: typePage == 'deposit' }" @click="filterTypeDeposit('deposit')">List Deposit</span>
            <span class="tab" :class="{ activeTab: typePage == 'metal' }" @click="filterTypeDeposit('metal')">List Metal</span>
        </div>
        <list-deposit
            v-if="typePage == 'deposit'"
            :depositData="loadDeposit"
            :currentPage="currentPage"
            :dataClient="getDataClient"
            :perPage="perPage"
            :typeDeposit="type"
            :isLoadingData="isLoadingDeposit"
            @filterTable="setFilterTable"
            @changeRow="onChangeRow"
        ></list-deposit>
        <list-metal
            v-else
            :metalData="loadDeposit"
            :currentPage="currentPage"
            :perPage="perPage"
            :dataClient="getDataClient"
            :dataContract="getContract"
            :typeDeposit="type"
            :isLoadingData="isLoadingDeposit"
            @filterTable="setFilterTable"
        ></list-metal>
        <div class="wrap-pagination d-flex justify-content-end mt-3" v-if="!isLoadingDeposit">
            <b-pagination
                v-model="currentPage"
                :total-rows="lengthData"
                :per-page="perPage"
                @change="handlePageChange"
                aria-controls="new-table"
                first-number
                last-number
                v-show="lengthData > perPage"
            ></b-pagination>
        </div>
    </div>
</template>

<script>
import ListDeposit from "../../../../components/deposit/ListDeposit.vue";
import ListMetal from "../../../../components/deposit/ListMetal.vue";
export default {
    components: { ListDeposit, ListMetal },
    data() {
        return {
            currentPage: 1,
            perPage: 10,
            offset: 0,
            startDate: "",
            endDate: "",
            type: "onexchange",
            typePage: "deposit",
            activity: "deposit",
            owner: "",
            serialNumber: "",
            hallmark: "",
            dateType: "",
            status: "",
            contract_id: "",
            abxDeposite: Boolean,
        };
    },
    mounted() {
        this.getData(
            this.activity,
            this.perPage,
            this.currentPage,
            this.startDate,
            this.endDate,
            this.type,
            this.owner,
            this.serialNumber,
            this.hallmark,
            this.dateType,
            this.status,
            this.contract_id
        );

        this.$store.dispatch("getClientData", this.type);
        this.$store.dispatch("getContract");
    },
    methods: {
        setFilterTable(event) {
            this.getData(
                this.activity,
                this.perPage,
                this.currentPage,
                event.startDate,
                event.endDate,
                this.type,
                event.owner,
                event.serial_number,
                event.hallmark,
                event.dateType,
                event.status,
                event.contract_id
            );

            this.startDate = event.startDate;
            this.endDate = event.endDate;
            this.owner = event.owner;
            this.serialNumber = event.serial_number;
            this.hallmark = event.hallmark;
            this.dateType = event.dateType;
            this.status = event.status;
            this.contract_id = event.contract_id;
        },
        filterTypeDeposit(value) {
            this.typePage = value;
            this.getData(
                this.activity,
                this.perPage,
                this.currentPage,
                this.startDate,
                this.endDate,
                this.type,
                this.owner,
                this.serialNumber,
                this.hallmark,
                this.dateType,
                this.status,
                this.contract_id
            );
        },
        handlePageChange(value) {
            this.getData(
                this.activity,
                this.perPage,
                value,
                this.startDate,
                this.endDate,
                this.type,
                this.owner,
                this.serialNumber,
                this.hallmark,
                this.dateType,
                this.status,
                this.contract_id
            );
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        onChangeRow(value) {
            this.perPage = value;
            this.getData(
                this.activity,
                this.perPage,
                this.currentPage,
                this.startDate,
                this.endDate,
                this.type,
                this.owner,
                this.serialNumber,
                this.hallmark,
                this.dateType,
                this.status,
                this.contract_id
            );
        },
        getData(activity, perPage, currentPage, startDate, endDate, type, owner, serialNumber, hallmark, dateType, status, contract_id) {
            if (this.typePage == "deposit") {
                this.$store.dispatch("getDepositList", {
                    serialNumber: serialNumber,
                    perPage: perPage,
                    currentPage: currentPage,
                    owner: owner,
                    startDate: startDate,
                    endDate: endDate,
                    status: status,
                    type: type,
                });
            } else {
                this.$store.dispatch("getListMetal", {
                    activity: activity,
                    perPage: perPage,
                    currentPage: currentPage,
                    startDate: startDate,
                    endDate: endDate,
                    type: type,
                    owner: owner,
                    serialNumber: serialNumber,
                    hallmark: hallmark,
                    dateType: dateType,
                    status: status,
                    contract_id: contract_id,
                });
            }
        },
    },
    computed: {
        rows() {
            return (this.currentPage - 1) * this.perPage;
        },

        loadDeposit() {
            return this.$store.state.bullionModule.dataShow;
        },

        lengthData() {
            return this.$store.state.bullionModule.lengthData;
        },

        isLoadingDeposit() {
            return this.$store.state.bullionModule.isloading;
        },

        getDataClient() {
            return this.$store.state.bullionModule.clientData;
        },

        getContract() {
            return this.$store.state.bullionModule.contractData;
        },
    },
};
</script>

<style scoped>
/*.wrap-dashboard {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}*/

.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

.nav-tab {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #636366;
    padding: 0.8rem 0;
}

.tab {
    padding: 0.8rem 1rem;
    cursor: pointer;
}

.tab:hover,
.activeTab {
    color: var(--scondary-color);
    border-bottom: 2px solid var(--scondary-color);
}

label {
    width: 50%;
}

.select-input,
input {
    width: 100%;
}

/* .form-group {
  display: flex;
} */

.select-input,
input {
    border-radius: 5px;
    border: 1px solid #454545;
    padding: 0 10px;
    font-size: 13px;
}

.buuton-add {
    text-align: right;
    font-size: 13px;
}

/* .wrap-content {
  width: 98%;
  height: 100%;
  margin: 0 auto;
  padding: 20px 25px;
} */

.content {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.icon-trade {
    width: 25px;
}

.wrap-filter {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
}

.wrap-button {
    display: flex;
    justify-content: end;
    gap: 1rem;
}

.form-group input,
select {
    width: 100%;
    margin-bottom: 0.75rem;
    border: 1px solid #ddd;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
}

.form-group button {
    width: 100%;
    font-size: 13px !important;
}

.footer {
    background: var(--primary-color);
    color: white;
    padding: 20px 47px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    height: 62px;
}

.filter {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 12px;
    flex-wrap: wrap;
}

.wrap-input {
    margin-bottom: 10px;
}
.label-input {
    width: 108px;
}
/* select {
  width: 130px;
  border-radius: 3px;
  border-color: #a5a5a5a5;
  padding: 3px;
} */

.card {
    width: 314px;
    font-size: 14px;
    border-radius: 10px;
}

.card-header {
    padding: 1rem;
}

.buyer,
.seller,
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 12px;
    position: relative;
    left: 20px;
}

.title::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--scondary-color);
    left: -17px;
    top: 3px;
}

.buyer {
    margin-bottom: 20px;
}

.content-right {
    text-align: right;
}

.amount {
    font-weight: bold;
}

.body-modal {
    text-align: center;
    padding: 10px;
}

.content-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
}
.btn-primer {
    background: var(--scondary-color);
    border: none;
    color: #ffffff;
    padding: 5px 25px;
    border-radius: 5px;
}

.btn-scondary {
    border: solid 1px var(--scondary-color);
    color: var(--scondary-color);
    padding: 5px 25px;
    border-radius: 5px;
}

.btn-primer:hover,
.btn-scondary:hover {
    background-color: var(--scondary-color-hover);
    color: #ffffff;
}

.clearing {
    /* background: rgba(50, 90, 115, 0.1); */
    font-weight: 600;
    color: rgb(50, 90, 115);
    border-radius: 5px;
}
.operator {
    /* background: rgba(252, 108, 4, 0.1); */
    font-weight: 600;
    color: rgb(252, 108, 4);
    border-radius: 5px;
}
.abx {
    /* background: rgba(252, 108, 4, 0.1); */
    font-weight: 600;
    color: rgb(172, 143, 26);
    border-radius: 5px;
}
.vendor {
    /* background: rgba(252, 108, 4, 0.1); */
    font-weight: 600;
    color: rgb(250, 194, 75);
    border-radius: 5px;
}

.reject {
    font-weight: 600;
    color: rgb(250, 119, 75);
    border-radius: 5px;
}

.stored {
    /* background: rgba(40, 167, 69, 0.1); */
    font-weight: 600;
    color: rgb(40, 167, 69);
    border-radius: 5px;
}
</style>
