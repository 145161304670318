<template>
    <div class="list-metal">
        <div class="text-right mb-3">
            <b-button class="btn btn-info mr-1 btn-sm" @click="isShowFilter = !isShowFilter">Filter</b-button>
        </div>
        <filter-table v-if="isShowFilter" @setDataFilter="setDataFilterToParent" :dataClient="dataClient" :dataContract="dataContract"> </filter-table>
        <table>
            <thead>
                <tr id="top">
                    <th scope="col" style="width: 70px">No</th>
                    <th scope="col">Serial Number</th>
                    <th scope="col">Year</th>
                    <th scope="col" style="width: 200px">owner</th>
                    <th scope="col" style="width: 120px">Deposit Date</th>
                    <th scope="col" style="width: 120px" v-if="typeDeposit == 'offexchange'">Deposit Due Date</th>
                    <th scope="col">Fineness</th>
                    <th scope="col">Hallmark</th>
                    <th scope="col">Weight (gr)</th>
                    <th scope="col">Status</th>
                </tr>
            </thead>
            <tbody v-if="isLoadingData">
                <tr>
                    <td colspan="10">
                        <b-spinner></b-spinner>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="(data, i) in metalData" :key="i" v-show="metalData.length != 0">
                    <td data-label="No">{{ (currentPage - 1) * perPage + (i + 1) }}</td>
                    <td data-label="Serial Number">{{ data.serial_number }}</td>
                    <td data-label="Year">{{ data.year }}</td>
                    <td data-label="Owner">{{ data.owner }}</td>
                    <td data-label="Deposite Date">{{ data.metal_date | formatDate }}</td>
                    <td data-label="Deposite Due Date" v-if="typeDeposit == 'offexchange'">{{ data.due_date | formatDate }}</td>
                    <td data-label="Fineness">{{ data.fineness }}</td>
                    <td data-label="Hallmark">{{ data.hallmark }}</td>
                    <td data-label="Weight">{{ data.weight }}</td>
                    <td data-label="Status">
                        <div>
                            <span v-show="data.status == '001'" class="vendor">Vendor Emas Add Detail </span>
                            <span v-show="data.status == '002'" class="abx">KMI Approve</span>
                            <span v-show="data.status == '003'" class="operator">Operator Approve</span>
                            <span v-show="data.status == '004'" class="clearing">Clearing Approve</span>
                            <span v-show="data.status == '005'" class="stored">Stored</span>
                            <span v-show="data.status == '006'" class="reject">Reject</span>
                            <span v-show="data.status == '007'" class="stored">Withdrawal Process</span>
                            <span v-show="data.status == '008'" class="stored">Has Been Withdrawn</span>
                        </div>
                    </td>

                    <!-- <td data-label="Action" v-if="role != 8">
                        <b-button variant="outline-danger" size="sm" class="m-1" v-show="role == 2 && data.status == '003'">Decline</b-button>
                  <b-button variant="outline-success" size="sm" class="m-1" @click="sendId(data._id)" v-show="role == 1 && data.status == 1">Approve</b-button>
                  <b-button variant="outline-success" size="sm" class="m-1" @click="sendId(data._id)" v-show="role == 2 && data.status == 2">Approve</b-button>
                        <router-link :to="'/operator-detail-deposit/' + data._id" v-if="role == 5">
                            <b-button variant="outline-info" size="sm" class="m-1">Detail</b-button>
                        </router-link>
                        <router-link :to="'/detail-deposit/' + data._id" v-else>
                            <b-button variant="outline-info" size="sm" class="m-1">Detail</b-button>
                        </router-link>
                    </td> -->
                </tr>
                <tr v-show="metalData.length == 0">
                    <td colspan="10">No Data Available in Table</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import FilterTable from "../FilterTable.vue";
export default {
    components: { FilterTable },
    props: {
        metalData: Array,
        currentPage: Number,
        perPage: Number,
        dataClient: Array,
        dataContract: Array,
        typeDeposit: String,
        isLoadingData: Boolean,
    },
    data() {
        return {
            isShowFilter: false,
        };
    },
    methods: {
        setDataFilterToParent(event) {
            this.$emit("filterTable", event);
        },
    },
};
</script>

<style></style>
